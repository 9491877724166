  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  body {
    font-family: Montserrat, sans-serif;
    color: #1a1b1f;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
  }
  .page-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  button {
    border-width: 0;
  }
  input{
    border:none;
  }
  input:focus{
    outline: none;
  }

  h1 {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 44px;
    line-height: 62px;
    font-weight: 400;
  }
  
  h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 36px;
    line-height: 50px;
    font-weight: 400;
  }
  
  h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 46px;
    font-weight: 400;
  }
  
  h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 38px;
    font-weight: 400;
  }
  
  h5 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 34px;
    font-weight: 500;
  }
  
  h6 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
  }
  
  p {
    margin-bottom: 10px;
    display: inline; /* Override default block behavior */
  }
  
  a {
    -webkit-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    color: #1a1b1f;
    text-decoration: underline;
  }
  
  a:hover {
    color: #32343a;
  }
  
  a:active {
    color: #43464d;
  }
  
  ul {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 40px;
    list-style-type: disc;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  img {
    display: block;
  }
  
  label {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  blockquote {
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 15px 30px;
    border-left: 5px solid #e2e2e2;
    font-size: 20px;
    line-height: 34px;
  }
  
  figure {
    margin-top: 25px;
    padding-bottom: 20px;
  }
  
  figcaption {
    margin-top: 5px;
    opacity: 0.6;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
  }
  
  .container {
    width: 100%;
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .paragraph-tiny {
    font-size: 12px;
    line-height: 20px;
  }
  
  .paragraph-light {
    opacity: 0.6;
  }
  
  .section {
    margin-right: 2rem;
    margin-left: 2rem;
    flex: 1;
  }
  
  .section.cc-contact {
    padding-right: 80px;
    padding-left: 80px;
    background-color: #f4f4f4;
  }
  
  .button {
    padding: 12px 25px;
    border-radius: 0px;
    background-color: #1a1b1f;
    -webkit-transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
    transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
  }
  
  .button:hover {
    background-color: #32343a;
    color: #fff;
  }
  
  .button:active {
    background-color: #43464d;
  }
  
  .logo-link {
    z-index: 1;
  }
  
  .logo-link:hover {
    opacity: 0.8;
  }
  
  .logo-link:active {
    opacity: 0.7;
  }
  
  .logo-link.main.w--current {
    margin-right: 2rem;
  }

  /* TESTING */

  .dropdown-wrapper {
    position: relative;
  }
  
  .dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    display: none;
    min-width: 160px;
    z-index: 1;
  }
  
  .dropdown-wrapper:hover .dropdown-content {
    display: block;
  }
  
  .dropDownMenuItem,
  .dropdown-item {
    display: block;
    margin-right: 0.1rem;
    margin-left: 0rem;
    padding: 9px 1.4rem;
    background-color: #dbdbdb;
    opacity: 0.6;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .dropDownMenuItem:hover,
  .dropdown-item:hover {
    opacity: 0.9;
  }

  .dropDownMenu {
    position: absolute;
    background-color: #fff;
  }
  .dropDownMenuItem {
    margin-right: 0.1rem;
    margin-left: 0rem;
    margin-top: 0.1rem;
    padding: 9px 1.4rem;
    background-color: #dbdbdb;
    opacity: 0.6;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
  }
  .dropDownMenuItem:hover {
    opacity: 0.9;
  }
  .dropdown-item {
    margin-right: 0.1rem;
    margin-left: 0rem;
    padding: 9px 1.4rem;
    background-color: #dbdbdb;
    opacity: 0.6;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
  }


  .navigation-item {
    display: block;
    margin-right: 0.1rem;
    margin-left: 0rem;
    padding: 9px 1.4rem;
    background-color: #dbdbdb;
    opacity: 0.6;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
  }
  
  .navigation-item:hover {
    opacity: 0.9;
  }
  
  .navigation-item:active {
    opacity: 0.8;
  }
  
  .navigation-item.w--current {
    opacity: 1;
    color: #1a1b1f;
    font-weight: 600;
  }
  
  /* .navigation-item.w--current:hover {
    opacity: 0.8;
    color: #32343a;
  } */
  
  .navigation-item.w--current:active {
    opacity: 0.7;
    color: #32343a;
  }
  
  .navigation-item.mobile {
    display: none;
  }
  
  .navigation-item.first {
    margin-left: 0rem;
  }
  
  .navigation-item.last {
    margin-right: 0rem;
  }
  
  .navigation-items {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 1480px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .top-items{
    -webkit-justify-content: end;
    justify-content: end;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .top-bar{
    background-color: #fff;
  }
  .navigation {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0px 0px 1rem 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-color: transparent;
  }
  
  .navigation-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .text-field {
    margin-bottom: 18px;
    padding: 21px 20px;
    border: 1px solid #e4e4e4;
    border-radius: 0px;
    -webkit-transition: border-color 400ms ease;
    transition: border-color 400ms ease;
    font-size: 14px;
    line-height: 26px;
  }
  
  .text-field:hover {
    border-color: #e3e6eb;
  }
  
  .text-field:active {
    border-color: #43464d;
  }
  
  .text-field:focus {
    border-color: #43464d;
  }
  
  .text-field::-webkit-input-placeholder {
    color: rgba(50, 52, 58, 0.4);
  }
  
  .text-field:-ms-input-placeholder {
    color: rgba(50, 52, 58, 0.4);
  }
  
  .text-field::-ms-input-placeholder {
    color: rgba(50, 52, 58, 0.4);
  }
  
  .text-field::placeholder {
    color: rgba(50, 52, 58, 0.4);
  }
  
  .text-field.cc-textarea {
    height: 200px;
    padding-top: 12px;
  }
  
  .status-message {
    padding: 9px 30px;
    background-color: #202020;
    color: #fff;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
  }
  
  .status-message.cc-success-message {
    background-color: #12b878;
  }
  
  .status-message.cc-error-message {
    background-color: #db4b68;
  }
  
  .contact {
    padding-top: 80px;
    padding-bottom: 90px;
  }
  
  .contact-headline {
    width: 70%;
    margin-bottom: 40px;
  }
  
  .contact-form-grid {
    grid-column-gap: 30px;
    grid-row-gap: 10px;
  }
  
  .contact-form-wrap {
    width: 70%;
  }
  
  .footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 2rem;
    margin-left: 2rem;
    padding: 40px 0px;
    flex-shrink: 0;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .footer-bottom {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  .inlineTextLink {
    display: inline;
  }
  .disclaimer {
    font-size: 12px;
    line-height: 20px;
  }

  .footer-divider-two {
    width: 100%;
    height: 1px;
    background-color: #e4ebf3;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .footer-copyright {
    color: #3a4554;
  }

  .footer-social-block-two {
    justify-content: flex-start;
    align-items: center;
    margin-top: 0;
    margin-left: -12px;
    display: flex;
  }

  .webflow-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    opacity: 0.5;
    -webkit-transition: opacity 400ms ease;
    transition: opacity 400ms ease;
    text-decoration: none;
    text-transform: uppercase;
  }
  
  .webflow-link:hover {
    opacity: 1;
  }
  
  .webflow-link:active {
    opacity: 0.8;
  }
  
  .webflow-logo-tiny {
    margin-top: -2px;
    margin-right: 8px;
  }
  
  .footer-links {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .footer-item {
    margin-right: 20px;
    margin-left: 20px;
    opacity: 0.6;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    text-decoration: none;
    text-transform: uppercase;
  }
  
  .footer-item:hover {
    opacity: 0.9;
  }
  
  .footer-item:active {
    opacity: 0.8;
  }
  
  .protected-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 90px;
    padding-bottom: 100px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  
  .protected-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  
  .domain-forsale {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 2rem;
    padding-left: 2rem;
    background-color: #000;
    color: #ffffff;
    transition: background-color 0.3s, color 0.3s;
  }

  .domain-forsale:hover{
    background-color: #ffffff;
    color: #000;
  }
  
  .domain-forsale-text {
    color: inherit;
    font-size: 14px;
    text-align: center;
  }
  
  .navigation-items-right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 1480px;
    margin-top: 0.5rem;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .div-block {
    display: block;
    width: 100%;
    max-width: 1480px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .pagetitletext {
    font-size: 24px;
    font-weight: 600;
    text-decoration: none;
  }
  
  .text-span {
    font-weight: 600;
  }
  
  .text-block {
    text-decoration: none;
  }
  
  .footer-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 1480px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: top;
    -webkit-justify-content: space-between;
    -ms-flex-pack: top;
    justify-content: space-between;
  }
  
  .nav-domain-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .column {
    width: 25%;
  }
  
  .column.center {
    display: flex;
    flex: 1;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  
  .column.left {
    padding-right: 1rem;
    border: 1px solid #000;
  }
  
  .column.right {
    padding-left: 1rem;
    border: 1px solid #000;
  }
  
  .section-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 1480px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .div-block-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .div-block-3 {
    width: 15rem;
    margin-left: 0.5rem;
    margin-right: 2rem;
    padding: 4px 1rem;
    border-style: solid;
    border-width: 5px;
    border-color: #dbdbdb;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
  }
  .search-button{
    display: flex;
    align-items: center;
    align-self: center;
  }
  
  .text-block-2 {
    font-size: 12px;
    line-height: 20px;
  }
  
  /* .podm-wrapper {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    background-color: #AE1212;
  } */
  
  .text-block-3 {
    font-size: 30px;
    
  }
  
  .dubai-magazine {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #fff;
  }
  
  .part-of {
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  
  .sectionwrapper404{
    display: flex;
    flex: 1;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
  .nav-wrapper {
    margin-right: 2rem;
    margin-left: 2rem;
    padding-top: 0px;
    padding-bottom: 1rem;
  }
  
  .podm-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 1rem 2rem;
    max-width: 1480px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  
  .domain-nav {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  
  .domain-content {
    padding-right: 2rem;
    padding-left: 2rem;
    background-image: -webkit-gradient(linear, left top, right top, from(#d6ff5a), color-stop(49%, #59ffd0), to(#51d6ff));
    background-image: linear-gradient(90deg, #d6ff5a, #59ffd0 49%, #51d6ff);
  }
  
  .domain-nav-wrapper {
    width: 100%;
    max-width: 1480px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .any-domains {
    font-size: 34px;
  }
  
  .domain-content-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 20rem;
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .domain-content-block.right {
    width: 30%;
  }
  
  .domain-name {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 900;
  }
  
  .domain-content-text {
    font-size: 30px;
  }
  
  .button-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 1rem;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    font-weight: 700;
  }
  
  .button-2:hover {
    background-color: #fff;
    color: #000;
  }
  
  .fullscreen-sperator {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  @media screen and (max-width: 991px) {
    .section.cc-contact {
      padding-right: 0px;
      padding-left: 0px;
    }
  
    .button {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  
    .logo-link.w--current {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  
    .menu-icon {
      display: block;
    }
  
    .navigation-item {
      padding: 15px 30px;
      -webkit-transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
      transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
      text-align: center;
    }
  
    .navigation-item:hover {
      background-color: #f7f8f9;
    }
  
    .navigation-item:active {
      background-color: #eef0f3;
    }
  
    .navigation-item.mobile {
      display: block;
    }
  
    .navigation-items {
      background-color: #fff;
    }
  
    .navigation {
      margin-right: 0rem;
      margin-bottom: 50px;
      margin-left: 0rem;
      padding: 25px 30px;
    }
  
    .menu-button {
      padding: 0px;
    }
  
    .menu-button.w--open {
      background-color: transparent;
    }
  
    .navigation-wrap {
      margin-right: 0px;
    }
  
    .contact {
      width: auto;
      padding: 30px 50px 40px;
    }
  
    .contact-headline {
      width: 100%;
    }
  
    .contact-form-wrap {
      width: 100%;
    }
  
    .navigation-items-right {
      display: none;
      background-color: #fff;
    }
  
    .nav-domain-wrapper {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 767px) {
    .container {
      text-align: center;
    }
  
    .section {
      margin-right: 15px;
      margin-left: 15px;
    }
  
    .section.cc-contact {
      padding: 15px;
    }
  
    .logo-link {
      padding-left: 0px;
    }
  
    .navigation {
      padding: 20px 30px;
    }
  
    .text-field.cc-textarea {
      text-align: left;
    }
  
    .contact {
      padding-right: 30px;
      padding-left: 30px;
    }
  
    .contact-form-grid {
      grid-column-gap: 30px;
      grid-template-areas: "."
        "."
        ".";
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      -ms-grid-rows: auto auto auto;
      grid-template-rows: auto auto auto;
    }
  
    .contact-form {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  
    .contact-form-wrap {
      text-align: left;
    }
  
    .footer {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      text-align: center;
    }
  
    .webflow-link {
      margin-bottom: 15px;
    }
  
    .footer-links {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  
    .footer-item {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 0px;
    }
  
    .nav-domain-wrapper {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  
    .column {
      width: 100%;
    }
  
    .column.center {
      width: 100%;
    }
  
    .section-wrapper {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 479px) {
    .navigation {
      margin-right: 0rem;
      margin-left: 0rem;
      padding-right: 20px;
      padding-left: 20px;
    }
  
    .menu-button {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
  
    .menu-button.w--open {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
  
    .contact {
      padding-right: 15px;
      padding-left: 15px;
    }
  
    .contact-form {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  
    .contact-form-wrap {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  
    .footer {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  
    .webflow-link {
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  
    .footer-item.top {
      margin-top: 0px;
    }
  
    .domain-forsale {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  
    .domain-forsale-text {
      font-size: 0.55rem;
    }
  
    .pagetitletext {
      font-size: 20px;
    }
  
    .text-block {
      font-size: 14px;
    }
  
    .footer-wrapper {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  
    .nav-domain-wrapper {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
    
  }
  /*   
  .Magazine{
    max-width: 1280px;
    width: 100%;
    padding-right: 16;
    padding-left: 16;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  .NavBar{
    display: flex;
    flex-direction: row;
  }
  .NavItem{
    padding: 1px 10px 1px 10px;
    background-color: #61dafb;
    margin-right: 5px;
    
  }
  .NavItem:hover{
    background-color: aquamarine;
    cursor: pointer;
  }

  .PageTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .PageTitleText{
    margin-right: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
  }
  .ColumnSection{
    display: flex;
    flex-direction: row;
  }
  .column{
    width: 33.33%;

  }
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  

  /* WEBFLOW CSS */
  